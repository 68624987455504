import { graphql, Link, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import ComparisonTable from "../components/comparisonPage/ComparisonTable";
import DuctilityLink from "../components/comparisonPage/DuctilityLink";
import RoiLink from "../components/comparisonPage/RoiLink";
import SavingsTable from "../components/comparisonPage/SavingsTable";
import TitleWithCogImage from "../components/comparisonPage/TitleWithCogImage";

import Layout from "../components/Layout";
import Hero from "../components/shared/Hero/Hero";
import HideOnScreen from "../components/shared/HideOnScreen";
import ProductDisplay from "../components/shared/ProductsDisplay/ProductsDisplay";
import TextButtonsImage from "../components/shared/TextButtonsImage";
import TextWithTitle from "../components/shared/TextWithTitle/TextWithTitle";
import unescape from "../utils/usescape";
import CMS from "netlify-cms-app";

function PipVsTensilePage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: TRACED_SVG)
        }
      }
      benchtop: file(relativePath: { eq: "benchtop_render_v3_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      mobileImage: file(relativePath: { eq: "benchtop_render_v3_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      markdownRemark(fileAbsolutePath: { regex: "/vsTensile/" }) {
        frontmatter {
          seoTitle
          seoDescription
          hero {
            title
            button {
              text
              path
            }
          }
          basicInfo {
            title
            byline
            video
            button {
              text
              path
            }
          }
          featuresSection {
            title
            ourTitle
            theirsTitle
            features {
              name
              our
              theirs
            }
          }
          firstSection {
            title
            article
            roi {
              text
              link
            }
          }
          secondSection {
            title
            article
          }
        }
      }
    }
  `);

  const {
    seoTitle,
    seoDescription,
    hero,
    basicInfo,
    featuresSection,
    firstSection,
    secondSection,
  } = data.markdownRemark.frontmatter;

  const bg = undefined;
  const image = getImage(data.benchtop);
  const mobileImage = getImage(data.mobileImage);
  const light = undefined; 
  const className = "benchtop";
  const updatedLink = firstSection.roi.link === "#" ? "https://hubs.ly/Q01P6Z9v0" : firstSection.roi.link;

  return (
    <Layout
      seo={{
        title: seoTitle,
        description: seoDescription,
      }}
      backgroundColor="white"
      backgroundColorUnderneath="white"
    >
      <Hero
        title={unescape(hero.title)}
        secondaryButton={{
          text: hero.button.text,
          path: hero.button.path,
        }}
        byline={basicInfo.byline}
        hideBylineOnType="mobile"
        hideBylineOnBreakpoint="md"
        image={image}
        mobileImage={mobileImage}
        bg={bg}
        light={light}
      />
      <HideOnScreen variant="desktop" breakpoint="md">
        <TextButtonsImage
          title={unescape(basicInfo.title)}
          byline={basicInfo.byline}
          secondaryButton={{
            text: basicInfo.button.text,
            path: basicInfo.button.path,
            tier: "bordered",
            padding: true,
          }}
          video={basicInfo.video}
          hideOnMobile
          demoButton={false}
          variant="small"
        />
      </HideOnScreen>
      <ComparisonTable {...featuresSection} moreInfo={<DuctilityLink />} />
      <TextWithTitle title={unescape(firstSection.title)} divider>
        {firstSection.article}
        <HideOnScreen variant="desktop" breakpoint={"md"}>
          <a href={updatedLink}><strong>
            {firstSection.roi.text}
            </strong>
          </a>
        </HideOnScreen>
        <SavingsTable />
      </TextWithTitle>
      <TextWithTitle
        title={<TitleWithCogImage title={unescape(secondSection.title)} />}
      >
        {secondSection.article}
      </TextWithTitle>

      <ProductDisplay />
    </Layout>
  );
}

export default PipVsTensilePage;
