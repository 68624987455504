import React from "react";
import {
  paragraphTextLarge,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const SavingsTable = () => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Machine type</th>
            <th>#tests</th>
            <th>Total Cost (£)</th>
            <th>
              Cumulative
              <br />
              Time (hours)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Tensile Tester</td>
            <td>50</td>
            <td>2000</td>
            <td>2000</td>
          </tr>
          <tr>
            <td>PLX-Benchtop</td>
            <td>50</td>
            <td>200</td>
            <td>25</td>
          </tr>
        </tbody>
      </Table>
      <Disclaimer>* of 450 organisations surveyed by Plastometrex</Disclaimer>
    </>
  );
};

export default SavingsTable;

const Table = styled("table", {
  marginTop: 32,
  marginBottom: 32,
  "@md": {
    marginTop: 64,
    marginBottom: 64,
  },
  borderCollapse: "collapse",
  textAlign: "center",
  width: "100%",
  thead: {
    backgroundColor: "$blue100",

    "@md": {
      ...paragraphTextLarge,
      color: "$white",
    },

    th: {
      ...paragraphTextSmall,
      fontWeight: 500,
      color: "$white",
      fontSize: 11,
      border: "none",
      padding: 4,
      lineHeight: "12px",
      "@xs": {
        fontSize: 14,
        padding: 8,
      },
      "@sm": {
        ...paragraphTextSmall,
        lineHeight: "20px",

        color: "$white",
      },
      "@md": {
        padding: 8,
      },
    },
  },
  tr: {
    border: "0.5px solid $black55",
  },
  td: {
    ...paragraphTextSmall,
    fontSize: 10,
    padding: 0,
    "&:first-child": {
      paddingLeft: 6,
    },
    "@xs": {
      padding: "4px 6px",
    },
    "@sm": {
      fontSize: 12,
      padding: 6,
    },
    "@md": {
      fontSize: 16,
      padding: 16,
    },
  },
});

const Disclaimer = styled("p", {
  color: "#adadad",
  fontSize: 12,
  "@md": {
    fontSize: 16,
  },
});
