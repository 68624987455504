import React from "react";
import { styled } from "../../stitches/stitches.config";
import Cog from "./assets/cog.inline.svg";

interface TitleWithCogImageProps {
  title: string;
}

const TitleWithCogImage = ({ title }: TitleWithCogImageProps) => {
  return (
    <>
      {title}
      <ImageWrapper>
        <Cog />
      </ImageWrapper>
    </>
  );
};

export default TitleWithCogImage;

const ImageWrapper = styled("div", {
  maxWidth: 85,
  marginTop: -20,
  "@sm": {
    marginTop: 0,

    maxWidth: 100,
    marginRight: 50,
  },
  "@md": {
    maxWidth: 130,
    paddingBottom: 10,
  },
  "@lg": {
    maxWidth: "unset",

    paddingBottom: 20,
  },
  svg: {
    maxWidth: "100%",
  },
});
