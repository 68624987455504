import { Link } from "gatsby";
import React from "react";
import { styled } from "../../stitches/stitches.config";

const DuctilityLink = () => {
  return (
    <>
      *To learn more about ductility see our{" "}
      <StrongLink to="/blogs/mechanical-testing-myths-debunked">
        blog here
      </StrongLink>
    </>
  );
};

const StrongLink = styled(Link, {
  color: "$blue100",
  fontWeight: 700,
});

export default DuctilityLink;
